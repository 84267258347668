<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    :dark="dark"
    :right="$vuetify.rtl"
    :src="image"
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
  >
    <template
      v-if="drawerImage"
      #img="props"
    >
      <v-img
        :key="image"
        v-bind="props"
      />
    </template>

    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3 mb-2" />

      <default-list :items="items" />
    </div>
    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify'
  import { mapGetters } from 'vuex'

  export default {
    name: 'DefaultDrawer',

    components: {
      DefaultDrawerHeader: () => import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader'
      ),
      DefaultList: () => import(
        /* webpackChunkName: "default-list" */
        './List'
      ),
    },

    data: () => ({
      image: '/assets/logo.png',
    }),

    computed: {
      ...mapGetters({
        token: 'admin/getAccessToken',
        admin: 'admin/getUser',
      }),
      ...sync('ui', [
        'dark',
        'drawer',
        'drawerImage',
        'mini',
      ]),
      items () {
        return [
          { type: 'link', title: 'Home', icon: 'mdi-home', route: '/', allowed: !this.authenticated },
          { type: 'link', title: 'Authentication', icon: 'mdi-login', route: '/user/authentication', allowed: !this.authenticated },
          { type: 'link', title: 'Dashboard', icon: 'mdi-view-dashboard', route: '/', allowed: this.authenticated },
          { type: 'link', title: 'Buy Requests', icon: 'mdi-view-dashboard', route: '/buy-requests', allowed: this.authenticated },
          { type: 'link', title: 'Products List', icon: 'mdi-beaker', route: '/nomenclature/list', allowed: this.authenticated },
          {
            type: 'group',
            title: 'Auction',
            icon: 'mdi-storefront',
            allowed: this.authenticated,
            items: [
              { type: 'link', title: 'Grower', icon: 'mdi-shopping', route: '/auction/grower', allowed: this.authenticated },
              { type: 'link', title: 'Buy', icon: 'mdi-shopping', route: '/auction/lots', allowed: this.authenticated },
            ],
          },
          { type: 'link', title: 'Customers', icon: 'mdi-account-group', route: '/customers/list', allowed: this.authenticated },
          { type: 'link', title: 'Users', icon: 'mdi-list-box', route: '/users/list', allowed: this.authenticated },
          { type: 'link', title: 'Roles', icon: 'mdi-list-box', route: '/roles/list', allowed: this.authenticated },
        ]
      },
      authenticated () {
        return !!this.admin.id
      },
    },
  }
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
